import Cookies from 'js-cookie';

import { SolanaProviderInterface } from '@shared/api/types/globalTools';
import { storageKeys } from '@shared/constants';

/**
 * Checks if the Phantom wallet browser extension is enabled.
 */
const isPhantomWalletInstalled = (): boolean => {
  return Boolean(window.solana && window.solana.isPhantom);
};

/**
 * Gets the Solana provider if Phantom is installed.
 */
const getSolanaProvider = (): SolanaProviderInterface => {
  if (!window.solana) {
    throw new Error('Solana is not available');
  }

  return window.solana;
};

/**
 * Initiates the connection to the Solana wallet.
 */
const connect = () => {
  const solana = getSolanaProvider();

  return solana.connect();
};

/**
 * Signs a message using the Solana wallet.
 */

const uint8ToBase64 = (uint8Array: Uint8Array) => {
  let binary = '';

  for (let i = 0; i < uint8Array.length; i++) {
    binary += String.fromCharCode(uint8Array[i]);
  }

  return window.btoa(binary);
};

const signMessage = async (message: string): Promise<string> => {
  const solana = getSolanaProvider();
  const encodedMessage = new TextEncoder().encode(message);
  const { signature: signedMessage } = await solana.signMessage(encodedMessage, 'utf8');
  return uint8ToBase64(signedMessage);
};

/**
 * Gets the Solana wallet address.
 */
const getAddress = async (): Promise<string> => {
  if (!window.solana) {
    throw new Error('Solana is not available');
  }

  const response = await window.solana.connect();
  return response.publicKey.toString();
};

/**
 * Subscribes to changes in the connected account.
 * Logs out if the connected wallet is Phantom.
 */
const subscribeToAccountChanges = () => {
  const solana = getSolanaProvider();
  solana.on('accountChanged', () => {
    const isActivePhantom = Cookies.get(storageKeys.MSQ_CONNECTED_WALLET_TYPE) === 'Phantom';

    if (isActivePhantom && window && '__logoutClient' in window) {
      window.__logoutClient?.();
    }
  });
};

/**
 * Sends a transaction using the Solana wallet.
 */
const sendTransaction = async (hash: any): Promise<string> => {
  const solana = getSolanaProvider();

  await connect();

  const { signature } = await solana.request({
    method: 'signAndSendTransaction',
    params: {
      message: hash,
      options: {
        skipPreflight: true,
      },
    },
  });
  return signature;
};

const solanaAPI = {
  isPhantomWalletInstalled,
  getProvider: getSolanaProvider,
  connect,
  signMessage,
  getAddress,
  subscribeToAccountChanges,
  sendTransaction,
};

export default solanaAPI;
